import React from 'react'
import { Container, Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.filter(post => !post.node.frontmatter.hidden && post.node.frontmatter.contentType === 'blog')
  return (
    <Layout>
        <Helmet>
          <title>Τα νέα μας | Σπιτικό Παπακωνσταντίνου</title>
          <meta name="description" content="Τα νέα μας." />
        </Helmet>
      <Container className="product-archive">
      <h1><b>Τα Νέα Μας</b></h1>
        {posts.map(({ node: post }) => (
          <Link to={post.frontmatter.path}>
          <Card style={{ marginBottom: 10 }} key={post.id}>
            <CardBody>
              <img className="featuredImage" src={post.frontmatter.thumbnail} alt={post.frontmatter.title}/>
              <CardTitle>{post.frontmatter.title}</CardTitle>
              <CardSubtitle style={{ marginBottom: 10 }}>{post.frontmatter.date}</CardSubtitle>
              <CardText>{post.excerpt}</CardText>
            </CardBody>
          </Card>
          </Link>
        ))}
        
      </Container>
    </Layout>
  )
}


export default BlogPage

export const neaQuery = graphql`
  query NeaQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 150)
          id
          frontmatter {
            title
            contentType
            thumbnail
            date(formatString: "DD-MM-YYYY")
            path
            hidden
          }
        }
      }
    }
  }
`
